// @import "utils"
@import "variables";
@import "preloader";
@import "page-404";
@import "theme-dark";

html {
  overflow-y: hidden;
  user-select: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid blue;
  transition: background-color 5000s ease-in-out 0s; }

.box-container-icon {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 20px;
  position: absolute;
  right: 0px;
  background: #fafafa;

  @media (min-width: 1550px) {
    // position: absolute;
 } }    // right: 0px;

::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
 }  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.);

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fbfbfb; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d9d9d9;

  &:hover {
    background-color: #cacaca;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6); }

  &:active {
    background-color: #bbbbbb; } }


.MuiAlert-message {
  & div {
    display: flex !important;
    & ic {
      font-size: 18px !important; } } }

.loaderAction {
  position: absolute;
  width: calc(100% - 28px);
  margin-top: -4.8px;
  margin-left: -4.8px;
  border-radius: 4px;
  height: 100%;
  z-index: 1;
  background: rgba(#fbfbfb, 0.7); }

.badgeStyleApp {
  display: inline-block;
  padding: .4em .8em;
  font-size: 110%;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  color: #000000;
  background-color: #d8e6f1;

  &.sm {
    padding: .2em .7em;
    font-size: 95%;
    line-height: 1.2; }

  &.badge-primary {
    color: #fff;
    background-color: #007bff; }

  &.badge-secondary {
    color: #fff;
    background-color: #6c757d; }

  &.badge-info {
    color: #fff;
    background-color: #17a2b8; }

  &.badge-warning {
    color: #212529;
    background-color: #ffc107; } }

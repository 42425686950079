.textPageNot-Found {
  h1, h2, h3, h4, h5, h6, .subtitle-desc {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 300;
    line-height: 1.5em; }

  h1 {
    line-height: 1.15em;
    color: rgba($_negro,.7);
    font-size: 13.7em !important;
    margin-top: 30px;
    min-height: 32px;
    font-family: "Roboto Slab", "Times New Roman", serif;
    font-weight: 700;
    margin-bottom: 25px;
    letter-spacing: 14px !important;
    text-decoration: none; }

  h2 {
    color: rgba($_negro,.7);
    font-size: 1.85rem !important;
    margin-top: 0;
    margin-bottom: 8px; }

  h3 {
    color: rgba($_negro,.7);
    font-size: 1.225rem;
    margin-top: 0;
    margin-bottom: 8px; }

  .subtitle-desc {
    font-size: 0.9rem !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: rgba($_negro,.7); } }


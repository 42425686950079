.theme-dark-app {

  .box-container-icon {
    border: 1px solid transparent;
    border-radius: 20px;
    background: transparent; }

  .ec_preloaderPage {
    background: rgba($_negro, 0.4);

    .container-preloader {
      background-color: rgba($_negro, 0.8);
      box-shadow: rgba(69, 72, 83, 0.27) 0px 0px 0.25em, rgba(79, 88, 104, 0.05) 0px 0.25em 1em; }
    h3 {
      color: rgba($_gris1, 0.9); } }

  .lds-ellipsis div {
    background: rgba($_gris1, 0.9); }


  ::-webkit-scrollbar {
    width: 0.5em; }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
    background-color: #606060; }

  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,.3);
    background-color: #999999;

    &:hover {
      background-color: #aeaeae;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.6); }

    &:active {
        background-color: #aad18d; } }

  .textPageNot-Found {
    h1, h2, h3 {
     color: rgba(255,255,255,.85); } }

  .loaderAction {
    background: rgba($_gris600, 0.75); } }

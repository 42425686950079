$_naranjo: #F48024;
$_negro: #212121;
$_gris1: #e4e4e4;
$_gris2: #a0a0a0;
$_gris600: #757575;
$_gris400: #bfbfbf;

$_azul: #0080ff;
$_rojo: #f44336;
$_verde: #4caf50;
$_verdeHover: #429746;

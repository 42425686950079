.ec_preloaderPage {
  background: rgba($_gris400, .3);
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  @media (min-width: 1280px) {
    left: 240px;
    width: calc(100% - 240px); }

  .container-preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    padding: 3px 30px;
    border-radius: 6px;
    background-color: rgba(219, 219, 219, 0.7);
    box-shadow: rgba(99, 105, 124, 0.27) 0px 0px 0.25em, rgba(111, 151, 226, 0.05) 0px 0.25em 1em; }

  h3 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    color: rgba($_negro, 0.9);
    text-transform: uppercase;
    margin: 8px;
    margin-bottom: 16px; }

  &.fade-enter {
    opacity: 0.01;

    &.fade-enter-active {
      opacity: 1;
      transition: opacity 200ms ease-in; } }

  &.fade-exit {
    opacity: 1;

    h3, .container-preloader {
      opacity: 0.01;
      transition: opacity 380ms ease-in; }

    &.fade-exit-active {
      opacity: 0.01;
      transition: opacity 480ms ease-in; } }

  .cont-preloader {
    position: absolute;
    margin: 0 auto; } }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 62px;
  height: 45px;
  margin-top: -54px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba($_negro, 0.6);
  // background: $_negro;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }

  100% {
    transform: scale(1); } }


@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }

  100% {
    transform: scale(0); } }


@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }

  100% {
    transform: translate(19px, 0); } }


